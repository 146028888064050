import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { patchApiReq } from "utils/ApiHandlers";
import { postApiReq } from "utils/ApiHandlers";
import {
  changePasswordValidation,
  UpdateProfileValidation,
} from "utils/validation";
// import Banner from "./components/Banner";
// import General from "./components/General";
// import Notification from "./components/Notification";
// import Project from "./components/Project";
// import Storage from "./components/Storage";
// import Upload from "./components/Upload";-
import { parseYupError, isYupError } from "../../../utils/Yup";
import InputField from "components/fields/InputField";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { getReq } from "utils/ApiHandlers";
import Loader from "components/loader";

const ProfileOverview = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [eye, setEye] = useState(false);
  const [eye1, setEye1] = useState(false);
  const [eye2, setEye2] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [adminData, setAdminData] = useState(null);
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [formError, setFormError] = useState({});

  useEffect(() => {
    if (adminData !== null) {
      setForm((prevForm) => ({
        ...prevForm,
        firstname: adminData.firstname || "",
        lastname: adminData.lastname || "",
        email: adminData.email || "",
      }));
    }
  }, [adminData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Changing ${name} to ${value}`);

    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    setFormError((prevFormError) => ({
      ...prevFormError,
      [name]: "",
    }));
  };

  const getAdminDetails = async () => {
    setIsLoading(true);
    try {
      const responce = await getReq("/admin");
      if (responce.status) {
        setAdminData(responce.data);
        setIsLoading(false);
      } else {
        console.log(responce.error);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Error", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAdminDetails();
  }, []);

  const handleUserInfoUpdate = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      setFormError({});
      await UpdateProfileValidation.validate(form, {
        abortEarly: false,
      });
      const res = await patchApiReq("/admin", form);
      console.log("responce", res);
      const { status, data } = res;
      if (status) {
        setIsLoading(false);
        toast.success("Updated Successfully");
        setIsDisable(true);
        getAdminDetails();

        console.log("updates");
      } else {
        setIsLoading(false);
        toast.error(data.message);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      } else {
        toast.error(error?.data?.message);
      }
      setIsLoading(false);
    }
  };

  const handleChangePasswordSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      setFormError({});
      await changePasswordValidation.validate(form, {
        abortEarly: false,
      });
      const res = await postApiReq("/admin/change-password", form);
      if (res.data.status === "success") {
        setIsLoading(false);
        toast.success("Password Changed Successfully");
        // dispatch(getAuth());
        setForm((prevForm) => ({
          ...prevForm,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        }));
      } else {
        setIsLoading(false);
        toast.error(res?.data?.message);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      } else {
        toast.error(error.data.message);
      }
      toast.error("plese fill details");
      setIsLoading(false);
    }
  };

  const handleDetailsChange = () => {
    setIsDetail(!isDetail);
    setForm({});
    setFormError({});
  };
  const editButton = () => {
    setIsDisable(!isDisable);
    setFormError({});
  };
  console.log(form, "form");
  console.log(adminData, "adminData");
  return (
    <div className="flex w-full flex-col gap-5  dark:text-white">
      <Loader isLoading={isLoading} />
      {!isDetail ? (
        <div className="flex w-full items-start justify-start">
          <div className="m-auto max-w-[700px]">
            <div className="grid w-[700px] grid-cols-2  gap-2 py-6 px-6 ">
              {/*  <div className="input_field mt-2">
              <InputField
                  // variant="auth"
                  extra="mb-3"
                  label="Username"
                  placeholder="Please enter user name"
                  id="username"
                  name="username"
                  type="text"
                  value={form.username}
                  onChange={handleChange}
                  state={formError.username ? "error" : "success"}
                  error={formError.username}
                  disabled={isDisable}
                /> 
              </div>*/}

              <div className="input_field mt-2">
                <InputField
                  // variant="auth"
                  extra="mb-3"
                  label="First Name"
                  placeholder="Please enter first name"
                  id="firstname"
                  name="firstname"
                  type="text"
                  value={form.firstname || ""}
                  onChange={handleChange}
                  state={formError.firstname ? "error" : "success"}
                  error={formError.firstname}
                  disabled={isDisable}
                />
              </div>

              <div className="input_field mt-2">
                <InputField
                  // variant="auth"
                  extra="mb-3"
                  label="Last Name"
                  placeholder="Please enter last name"
                  id="lastname"
                  name="lastname"
                  type="text"
                  value={form.lastname || ""}
                  onChange={handleChange}
                  state={formError.lastname ? "error" : "success"}
                  error={formError.lastname}
                  disabled={isDisable}
                />
              </div>

              <div className="input_field mt-2">
                <InputField
                  // variant="auth"
                  extra="mb-3"
                  label="Email"
                  placeholder="Please enter email"
                  id="email"
                  name="email"
                  type="text"
                  value={adminData?.email || ""}
                  onChange={handleChange}
                  // state={formError.email ? "error" : "success"}
                  disabled={true}
                />
              </div>

              {/* <div className="input_field mt-2">
                <InputField
                  // variant="auth"
                  extra="mb-3"
                  label="Country"
                  placeholder="Please enter country name"
                  id="country"
                  name="country"
                  type="text"
                  value={form.country}
                  onChange={handleChange}
                  state={formError.country ? "error" : "success"}
                  error={formError.country}
                  disabled={isDisable}
                />
              </div> */}
            </div>
            <div className="flex flex-row-reverse gap-5">
              <button
                onClick={() => {
                  handleDetailsChange();
                  setIsLoading(false);
                }}
                type="button"
                className="linear mt-2 w-[200px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Change Password
              </button>
              <button
                type="button"
                onClick={handleUserInfoUpdate}
                className="linear mt-2 w-[200px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Update
              </button>
            </div>
          </div>
          <div
            className="linear mx-4 mt-[66px] cursor-pointer rounded-xl bg-brand-500 py-2 px-6  text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={editButton}
          >
            Edit
          </div>
        </div>
      ) : (
        <div className="flex w-full items-center justify-start">
          <div className="m-auto max-w-[500px]">
            <div className="flex w-[350px] flex-col gap-2 py-6 px-6 ">
              <div className="input_field relative mt-3">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="oldPassword"
                  placeholder="Old Password"
                  id="oldPassword"
                  name="oldPassword"
                  type={eye ? "text" : "password"}
                  value={form.oldPassword || ""}
                  onChange={handleChange}
                  state={formError.oldPassword ? "error" : "success"}
                  error={formError.oldPassword}
                />
                <span
                  className="absolute right-4 top-[50px] z-10 translate-y-[-50%] cursor-pointer"
                  onClick={() => setEye(!eye)}
                >
                  {eye ? (
                    <FiEye className="btn-blu" />
                  ) : (
                    <FiEyeOff className="btn-blu" />
                  )}
                </span>
              </div>
              <div className="input_field relative mt-3">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="New Password"
                  placeholder="New Password"
                  id="New Password"
                  name="newPassword"
                  type={eye1 ? "text" : "password"}
                  value={form.newPassword || ""}
                  onChange={handleChange}
                  state={formError.newPassword ? "error" : "success"}
                  error={formError.newPassword}
                />

                <span
                  className="absolute right-4 top-[50px] z-10 translate-y-[-50%] cursor-pointer"
                  onClick={() => setEye1(!eye1)}
                >
                  {eye1 ? (
                    <FiEye className="btn-blu" />
                  ) : (
                    <FiEyeOff className="btn-blu" />
                  )}
                </span>
              </div>
              <div className="input_field relative mt-3">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="New Password"
                  placeholder="Confirm New Password"
                  id="confirmPassword"
                  name="confirmPassword"
                  type={eye2 ? "text" : "password"}
                  value={form.confirmPassword || ""}
                  onChange={handleChange}
                  state={formError.confirmPassword ? "error" : "success"}
                  error={formError.confirmPassword}
                />

                <span
                  className="absolute right-4 top-[50px] z-10 translate-y-[-50%] cursor-pointer"
                  onClick={() => setEye2(!eye2)}
                >
                  {eye2 ? (
                    <FiEye className="btn-blu" />
                  ) : (
                    <FiEyeOff className="btn-blu" />
                  )}
                </span>
              </div>
            </div>
            <div className="flex justify-center gap-5">
              <button
                onClick={() => {
                  handleDetailsChange();
                  setIsLoading(true);
                }}
                type="button"
                className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Back
              </button>
              <button
                type="button"
                onClick={handleChangePasswordSubmit}
                className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                {" "}
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileOverview;
